import * as React from 'react';
import styled from 'styled-components';
import { darkTheme } from './theme';

const Container = styled.div`
   background-color: white;
   color: black;
   box-sizing: border-box;
   border: 8px outset ${darkTheme.c0};
   padding: 15px;
   height: 100%;
`;

export const ContentContainer: React.SFC = props => (
   <Container>{props.children}</Container>
);