import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import logo from './logo.svg';

export type DocsPageProps = {
  mdContent: string;
  location: string;
};

export class DocsPage extends React.Component<DocsPageProps> {
  private ContentSpacing = styled.div`
  margin: 15px;
  `;

  render() {
    const isCurrentLocation: (loc: string) => boolean = loc => {
      return this.props.location === loc;
    };

    return (
      <this.ContentSpacing>
        <ReactMarkdown source={this.props.mdContent} />
      </this.ContentSpacing>
      );
    }

    private onClickInstall() {
      window.location.href = '/redirect/install';
    }

    private onClickRaiseIssue() {
      window.location.href = '/redirect/raise-issue';
    }
  }