import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { DocsPageContainer } from './DocsPageContainer';
import { Home } from './pages/Home';
import { Contact } from './pages/Contact';
import { NotFound } from './pages/NotFound';
import { Page } from './Page';
import { EntryProfile } from './EntryProfile';
import { ContentContainer } from './ContentContainer';
import { SideNav } from './SideNav';

const Empty: React.SFC = () => <></>;

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Page profile={<EntryProfile />} sideNav={<SideNav />}>
            <ContentContainer>
              <Switch>
                <Route exact={true} path="/" component={Empty} />
                <Route exact={true} path="/home" component={Home} />
                <Route exact={true} path="/contact" component={Contact} />
                <Route path="/docs/:page" component={DocsPageContainer} />
                <Route component={NotFound} />
              </Switch>
            </ContentContainer>
          </Page>
        </div>
      </Router>
    );
  }
}

export default App;
