import * as React from 'react';
import styled, {keyframes} from 'styled-components';

import { NavLink } from 'react-router-dom';
import { darkTheme } from './theme';

const animate = keyframes`
  from {
    color: ${darkTheme.c0};
  }

  to {
    color: #444444;
  }
`;

const width = 300;
const padding = 20;

export class EntryProfile extends React.PureComponent {
  private static Container = styled.div`
    display: flex;
    flex-direction: column;

    width: ${width}px;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border: 8px outset ${darkTheme.c0};
  `;

  private static ImageContainer = styled.div`
    margin: ${padding}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  private static NameContainer = styled.div`
    animation: ${animate} 2s alternate infinite;
    text-align: center;
  `;

  private static EnterButton = styled.div`
    .homeLink {
      background-color: ${darkTheme.c1};
      border: none;
      color: white;
      padding: 15px 0;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 100%;
    }

    .contactLink {
      background-color: ${darkTheme.c3};
      border: none;
      color: black;
      padding: 15px 0;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 100%;
    }
  `;

  render() {
    const { Container, ImageContainer, NameContainer, EnterButton } = EntryProfile;
    return (
      <Container>
        <ImageContainer><img src="https://gravatar.com/avatar/a534cad9b273de6c620b34687979e2a5?s=200" /></ImageContainer>
        <NameContainer><h1>Robert Massaioli</h1></NameContainer>
        <div></div>
        <EnterButton>
          <NavLink to="/home" className="homeLink">Home</NavLink>
          <NavLink to="/contact" className="contactLink">Contact</NavLink>
        </EnterButton>
      </Container>
    );
  }
}