import * as React from 'react';
import { SourceLoader } from '../SourceLoader';
import ReactMarkdown from 'react-markdown';

export const Contact: React.SFC = () => (
   <>
      <SourceLoader
         contentUrl={require('../docs/contact.md')}
         withSource={source => <ReactMarkdown source={source} />}
      />
   </>
);