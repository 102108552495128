import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';

type StyleProps = {
   isRoot: boolean;
};

const Container = styled.div<StyleProps>`
   min-height: 95vh;
   display: ${props => props.isRoot ? 'flex' : 'grid'};
   justify-content: ${props => props.isRoot ? 'center' : ''};
   align-items: ${props => props.isRoot ? 'center' : ''};
   flex-direction: row;
   grid-column-gap: 10px;
   grid-row-gap: 10px;
   grid-template-columns: 300px auto;
   grid-template-rows: auto 1fr;
   margin: 15px;
`;

const Profile = styled.div<StyleProps>`
   grid-column: 1 / span 1;
   grid-row: 1 / span 1;

   position: relative;
   left: ${props => props.isRoot ? '1px' : '0px'};
   transition: left 1s cubic-bezier(0,-100.16,.6,.3);
`;

const SideNav = styled.div<StyleProps>`
   grid-column: 1 / span 1;
   grid-row: 2 / span 1;
   vertical-align: top;

   width: ${props => props.isRoot ? '0' : '100%'};
   position: relative;
   opacity: ${props => props.isRoot ? '0' : '1'};
   top: ${props => props.isRoot ? '110%': '0'};
   transition: opacity 2s cubic-bezier(.05,.89,.56,.21), top 2s cubic-bezier(0.77, 0, 0.175, 1);
`;

const Content = styled.div<StyleProps>`
   grid-column: 2 / span 1;
   grid-row: 1 / span 2;

   width: ${props => props.isRoot ? '0' : '100%'};
   position: relative;
   opacity: ${props => props.isRoot ? '0' : '1'};
   left: ${props => props.isRoot ? '110%': '0'};
   transition: opacity 2s cubic-bezier(.05,.89,.56,.21), left 2s cubic-bezier(0.77, 0, 0.175, 1);
`;

export type Props = {
   profile: React.ReactElement<any>;
   sideNav: React.ReactElement<any>;
};

const PageWithRouter: React.SFC<Props & RouteComponentProps<string>> = props => {
   console.log(`Path: ${props.location.pathname}`);
   const isRoot = props.location.pathname === '/';

   return (
      <Container isRoot={isRoot}>
         <Profile isRoot={isRoot}>{props.profile}</Profile>
         <SideNav isRoot={isRoot}>{props.sideNav}</SideNav>
         <Content isRoot={isRoot}>{props.children}</Content>
      </Container>
   );
};

export const Page = withRouter(PageWithRouter);