import * as React from 'react';

export type Props = {
   contentUrl: string;
   withSource: (source: string) => React.ReactElement<any>;
};

type ContentState = {
   content: string;
};

type State = {
   loadState: 'loading' | 'load-failed' | ContentState;
};

export class SourceLoader extends React.PureComponent<Props, State> {
   readonly state: State = {
      loadState: 'loading'
   };

   componentDidMount() {
      fetch(this.props.contentUrl, {
         method: 'GET'
      }).then(rsp => rsp.text())
      .then(body => {
         this.setState({
            loadState: { content: body }
         });
      }).catch(() => {
         this.setState({
            loadState: 'load-failed'
         });
      });
  }

   render() {
      const { loadState } = this.state;

      if (loadState === 'loading') {
         return <div>Loading...</div>
      }

      if (loadState === 'load-failed') {
         return <div>Could not find the content!</div>
      }

      return this.props.withSource(loadState.content);
   }
}