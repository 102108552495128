import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { SourceLoader } from '../SourceLoader';

export class Home extends React.PureComponent {
   render() {
      return (
         <>
            <SourceLoader
               contentUrl={require('../docs/home.md')}
               withSource={source => <ReactMarkdown source={source} />}
            />
            <div>TODO Recent Activity</div>
         </>
      );
   }
}