export interface Theme {
  c0: string;
  c1: string;
  c2: string;
  c3: string;
  c4: string;
}

export const darkTheme: Theme = {
  c0: '#484A47',
  c1: '#5C6D70',
  c2: '#CFFFB3',
  c3: '#ADE25D',
  c4: '#FCEC52'
};